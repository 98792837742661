


















































































































import { BatalhaoRegiao, VeiculoHistorico } from '@/core/models/geral';
import { BatalhaoService, OperacaoHistoricoService, SituacaoVeiculoService, VeiculoHistoricoService } from '@/core/services/geral';
import { PageBase } from '@/core/services/shared';
import { Component, Watch } from 'vue-property-decorator';

@Component
export default class HitoricoVeiculo extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  dialogHistorico: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  situacaoVeiculos: any[] = [];
  situacaoVeiculoService = new SituacaoVeiculoService();
  operacaoHistoricos: any[] = [];
  operacaoHistoricoService = new OperacaoHistoricoService();
  batalhaoService = new BatalhaoService();
  onSearchRegioes: any = null;
  isRegioesLoading: boolean = false;
  regioes: any[] = [];
  $refs!: {
    formLocalRoubo: HTMLFormElement
  }
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:"actions" ,sortable: false },
    { text: 'Placa', value: 'placa' },
    { text: 'Chassi', value: 'chassi' },
    { text: 'Situação', value: 'situacaoVeiculo.nome' },
    { text: 'Operação', value: 'operacao.nome' },
    { text: 'Data/Hora', value: 'dataHora' },
    { text: 'Local do Roubo', value: 'localRoubo.nome' },
    { text: 'Latitude', value: 'latitude' },
    { text: 'Longitude', value: 'longitude' },
    { text: 'Link Local', value: 'localizacaoLink' },
  ];
  service = new VeiculoHistoricoService();
  sheet: boolean = false;
  filtro: any = {
    placa: '',
    chassi: '',
    situacao: '',
    operacao: '',
    localRoubo: ''
  }
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  dialogLocalRoubo: boolean = false;
  validLocalRoubo: boolean = false;
  item: VeiculoHistorico = new VeiculoHistorico();
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  mounted() {
    this.situacaoVeiculoService.ListarTudo().then(
        res => {
            this.situacaoVeiculos = res.data.items;
            this.situacaoVeiculos.unshift(
                {
                    id: '',
                    nome: "Todos"
                }
            )
        },
        err => this.$swal('Aviso', err.response.data, 'warning')
    )
    this.operacaoHistoricoService.ListarTudo().then(
        res => {
            this.operacaoHistoricos = res.data.items;
            this.operacaoHistoricos.unshift(
                {
                    id: '',
                    nome: "Todos"
                }
            )
        },
        err => this.$swal('Aviso', err.response.data, 'warning')
    )
  }

  AbrirDialogAlterarLocalRoubo(item: any){
    this.dialogLocalRoubo = true;
    this.item = item;
    if (this.$refs.formLocalRoubo) {
      this.$refs.formLocalRoubo.resetValidation();
    }
  }

  CloseLocalRoubo(){
    if (this.$refs.formLocalRoubo) {
      this.$refs.formLocalRoubo.resetValidation();
    }
    this.dialogLocalRoubo = false;
  }

  SalvarLocalRoubo(){
    if (this.$refs.formLocalRoubo.validate()) {
      console.log(this.item)
      this.service.AtualizarLocalRoubo(this.item.id, this.item.localRouboId).then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.dialogLocalRoubo = false;
          this.item = new VeiculoHistorico();
          this.Atualizar();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
  }

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if (isFiltro == true) {
      this.options.page = 1;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.service.MontaFiltro(this.filtro), "Usuario, SituacaoVeiculo, Operacao, LocalRoubo.Batalhao").then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

    @Watch('onSearchRegioes')
    SearchRegioes (val: string) {

        if (this.isRegioesLoading) return;
        if(this.filtro.localRoubo) return;
        if (!val) return;

        this.isRegioesLoading = true
        this.batalhaoService.Regioes(val)
        .then(
            res => {
              this.regioes = res.data.value;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        )
        .finally(() => (this.isRegioesLoading = false));
    }

}

